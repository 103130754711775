/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        if(sessionStorage.alertdismissed === "true"){
          $(".alert").hide();
        } else {
          $(".alert").show();
        }

        var $hamburger = $(".hamburger");
        var $navcontainer = $('.nav-primary');
        /*var $sidebarnav = $('div#sidebarnav');
        var $topBar = $('div.fixed-bar');*/

        $hamburger.on("click", function(e) {
          $hamburger.toggleClass("is-active");
          $navcontainer.toggleClass('is-active');
        });

        jQuery('.accordion').on('click', function(e){
          jQuery('.panel').toggle(false);
          jQuery(this).next('.panel').slideToggle();
        });

      },
      finalize: function() {
        if(sessionStorage.alertdismissed === "true"){
          $(".alert").alert('close');
        }

        $('.alert').on('closed.bs.alert', function () {
          sessionStorage.alertdismissed = "true";
        });
      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        jQuery('.media-btn').on('click', function(e){

            sessionStorage.setItem('currentRestaurant', "all" );
        });
      }
    },
    'single_lokale': {
      finalize: function(){

        sessionStorage.setItem('currentRestaurant', jQuery('.store-location-content').attr('data-location-id') );
      }
    },
    // About us page, note the change from about-us to about_us.
    'reservation': {
      init: function() {

        if(sessionStorage.currentRestaurant) {
          ajaxLoadReservationContent( sessionStorage.currentRestaurant );
        } else {
          alert("no restaurant selected");
        }

        $(".alert").hide();
      }
    },
    'jobs': {
      init: function(){
        $(".alert").hide();
      }
    },
    'presse':{
      init: function(){

        if(sessionStorage.currentRestaurant !== "all") {
          ajaxgetpostmeta( sessionStorage.currentRestaurant );
        } else {
          jQuery('.presse-item').show();
        }

        $(".alert").hide();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
