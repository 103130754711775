function ajaxLoadReservationContent(pid){
	var result = "";
	jQuery.ajax({
		url: soobj.ajaxurl,
		type: "POST",
		async: true,
        dataType: "html",
		data: {
			action: 'my_load_ajax_content',
			postid: pid
		},
		beforeSend: function(){
			jQuery('.loader').removeClass('hidden');
		},
		success: function( res ) {
			
			jQuery('#reservation_frame_widget').append(res);
			jQuery('.loader').addClass('hidden');
		}
	});
}

function ajaxgetpostmeta(pid){
	var result = "";
	jQuery.ajax({
		url: soobj.ajaxurl,
		type: "POST",
		async: true,
        dataType: "html",
		data: {
			action: 'my_load_ajax_content_meta',
			postid: pid
		},
		beforeSend: function(){
			jQuery('.loader').removeClass('hidden');
		},
		success: function( res ) {
			jQuery('.' + res).show();
			jQuery('.loader').addClass('hidden');
		}
	});
}